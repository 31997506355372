import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Register() {
  return (
    <div className='h-screen '>
      <Navbar/>
        <div className='bg-black text-white text-center  px-24 py-32 '>
            <h1 className='font-bold underline text-2xl pb-5'>REGISTRATION</h1>
            <p>REGISTRATION ONLY AVAILABLE FOR OLD AND PRE-EXISTING CUSTOMERS AT THE MOMENT PLEASE CONTACT OUR CUSTOMER SERVICE FOR MORE INFORMATIONS!!.</p>
        </div>
      <Footer/>
    </div>
  )
}

export default Register;
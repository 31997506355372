import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {
  FaEnvelope,
  FaTelegram,
} from 'react-icons/fa';

function Contact() {
  return (
    <div className='h-screen'>
      <Navbar/>
        <div className='bg-black text-white justify-items-center text-center shadow-2xl shadow-white px-24 py-20 border-yellow-100'>
          <p className='font-bold text-lg'>Contact us</p>
          <div className='flex gap-5'>
          <div className='flex flex-1 justify-center mb-10 mt-5'>          
            <a href='https://t.me/TUniverse0'>
            <FaTelegram size={50} color='blue' className='p-2'/>
            <p className='pt-'>Telegram</p>
            </a>
          </div>
          <div className='flex flex-1 justify-center mb-10 mt-5'>          
            <a href='mailto:https://Support@tuniverseworld.com'>
            <FaEnvelope size={50} color='red' className='p-2'/>
            <p className=''>Email</p>
            </a>
          </div>
          </div>
        </div>
      <Footer/>
    </div>
  )
}

export default Contact;
import React from 'react'

function Error() {
  return (
    <div className='h-screen mx-auto'>
        <div className=' text-center align- px-24 py-40 '>
            <h1 className='font-bold text-2xl italic pb-5'>ERROR</h1>
            <p className='mt-20 text-xl bold italic'>404 error!!.</p>
            <p className=' text-xl'>page not found</p>
        </div>
    </div>
  )
}

export default Error;